<template>
  <mcr-button class="book-call-button" @click="bookCall">{{ label }}</mcr-button>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import AnalyticsFacebookPixelHandler from '@common/utils/analytics/analytics.facebook.pixel';
import AnalyticsHandler from '@common/utils/analytics/analytics.handler';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {initCalendlyScript} from '@common/utils/script.loader';

export default {
  created() {
    initCalendlyScript();
    window.addEventListener('message', this.calendlyMessageHandler);
  },
  destroyed() {
    window.removeEventListener('message', this.calendlyMessageHandler);
  },
  props: {
    url: {type: String, default: ''},
  },
  computed: {
    label() {
      return 'Book a Call';
    },
  },
  methods: {
    bookCall() {
      const prefill = this.$store.getters.userIsLoggedInState
        ? {
            name: this.$store.getters.userFullNameState,
            email: this.$store.getters.userEmailState,
          }
        : {};
      Calendly.initPopupWidget({url: this.url || process.env.VUE_APP_CALENDLY_URL, prefill});

      AnalyticsHandler.trackGoToProspectRequestClickEvent(this.label);
      AnalyticsFacebookPixelHandler.trackContactEvent();
      AnalyticsAmplitudeHandler.trackClickContactButton(this.label, false, getRoutePageName(this.$route), true);
    },
    calendlyMessageHandler(e) {
      if (e.data.event && e.data.event === 'calendly.event_scheduled') {
        this.$store.commit('saveProspectRequestEntryPointUrl', this.$route.fullPath);
      }
    },
  },
  components: {McrButton},
};
</script>

<style lang="scss" scoped>
.mcr-button.book-call-button {
  background-color: $mcr-dark-tan;

  &:hover {
    background-color: $mcr-tan;
  }
}
</style>
